/* src/components/Login.css */
.login {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
  gap: 20px;
}

.login-form {
  flex: 1;
  max-width: 300px;
}

.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.login label {
  font-size: 14px;
  align-self: flex-start;
}

.login input {
  padding: 8px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.login button:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button-container button {
  margin: 0 5px;
}

.password-requirements {
  flex: 1;
  max-width: 300px;
  text-align: left;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.password-requirements h3 {
  margin-top: 0;
}

.password-requirements ul {
  padding-left: 20px;
  margin: 0;
}

.password-requirements li {
  margin-bottom: 5px;
}
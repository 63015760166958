.dashboard {   
  font-family: Arial, sans-serif; 
}  

.sidebar {
  background-color: #f8f9fa;
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
  border-right: 1px solid #dee2e6;
}

.main-content {
  padding: 20px;
}

.folder {   
  cursor: pointer;   
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  text-align: left;
  flex-grow: 1;
}  

.folder:hover {   
  background-color: #e9ecef;
}

.folder.active {
  background-color: #53a1f0;
  color: white;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.file-item {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}

.file-name {
  margin-bottom: 10px;
  word-break: break-word;
  text-align: center;
}

.file-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.upload-section {   
  margin-top: 20px; 
}  

.progress-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.progress-info {
  flex-grow: 1;
}

.progress-info span {
  display: block;
  margin-bottom: 5px;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 20px;
}

.breadcrumb-item {
  cursor: pointer;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.file-list {
  width: 100%;
  border-collapse: collapse;
}

.file-list th, .file-list td {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: left;
}

.file-list th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.file-list tr:nth-child(even) {
  background-color: #f8f9fa;
}

.file-list tr:hover {
  background-color: #e9ecef;
}

.top-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-actions, .right-actions {
  display: flex;
  gap: 5px;
}

.right-actions .btn {
  margin-left: 5px;
  transition: all 0.3s ease;
}

.folder-item {
  display: flex;
  align-items: center;
  position: relative;
}

.delete-folder-btn {
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  right: 10px;
  padding: 5px;
  color: #dc3545;
}

.folder-item:hover .delete-folder-btn {
  opacity: 1;
}

.right-actions .btn.active {
  background-color: #013164;
  color: white;
}

.download-progress {
  width: 100%;
  margin-top: 10px;
}

.file-item .download-progress {
  margin-top: 10px;
}

.file-list .download-progress {
  width: 100%;
  margin-top: 5px;
}

.progress {
  height: 20px;
}

.progress-bar {
  line-height: 20px;
}
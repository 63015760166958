/* src/App.css */
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #071e7ae7;
}

.logo {
  height: 40px;
}

.main-nav {
  display: flex;
  gap: 1rem;
}

.nav-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 1rem;
}
/* 目前選取 */
.nav-link.active {
  color: #dddcdc;
}
/* 滑鼠經過 */
.nav-link:hover {
  color: #e4f6f4; /* 你可以根據需要調整這個顏色 */
}

.header-right {
  display: flex;
  gap: 1rem;
}
/* 右上按鈕 */
.search-button,
.auth-link-button {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none; /* Remove underline */
}

.App-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.introduction-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.introduction-title {
    text-align: center;
    margin-bottom: 40px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.introduction-image {
    width: 105%;
    max-width: 400px;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.introduction-content {
    padding: 20px;
    text-align: left;
}

.introduction-content h2 {
    margin-bottom: 20px;
}

.introduction-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.introduction-navigation button {
    min-width: 100px;
}

.single-image-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single-image-layout .image-container {
    flex: 1;
    padding-right: 25px;
}

.single-image-layout .introduction-content {
    flex: 1;
    padding-left: 20px;
}

@media (max-width: 768px) {
    .introduction-container {
        padding: 10px;
    }

    .introduction-content {
        padding: 10px;
    }

    .single-image-layout {
        flex-direction: column;
        align-items: flex-start;
    }

    .single-image-layout .image-container,
    .single-image-layout .introduction-content {
        padding: 0;
        margin-bottom: 20px;
    }
}

/* src/components/Home.css */
.home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-top: 50px;
  padding: 0 20px;
}

.home-content {
  flex: 1;
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.home-image img {
  max-width: 60%;
  height: auto;
}

.home-buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

.home-button-link {
  flex: 1;
  display: flex;
  align-items: stretch;
  text-decoration: none; /* Remove underline */
}

.home-button {
  padding: 10px 20px;
  font-size: 17px;
  cursor: pointer;
  border: none;
  /* background-color: #007bff; */
  background-color:#4cc3b7;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.home-button:hover {
  /* background-color: #0056b3; */
  background-color: #48aca2;

}

.full-width {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: stretch;
}
